<template>
  <div class="gold-rush">
    <div class="gold-tilte">
      {{ goldData[0] }}
    </div>
    <div class="gold-body" ref="gold-body" >
      <van-swipe :autoplay="autoplay" class="my-swipe" indicator-color="white" :loop="loop">
        <van-swipe-item v-for="item in goldrushdata" :key="item.id" @click="$router.push(`/note/${item.id}?from_id=oly`)">
          <div class="swipe-box">
            <div class="swipe-img"><img :src="item.firstPicture + '?x-oss-process=image/format,jpg'" alt="" /></div>
            <div class="swipe-title van-ellipsis">{{ item.title }}</div>
            <div v-if='showWhat' class="swipe-abstract van-multi-ellipsis--l2">{{ item.description }}</div>
          </div>
          <div v-if='!showWhat' class="seize-a-seat"></div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <olympic-nav v-if='showWhat ? this.goldData.length > 4 : this.goldData.length > 6' :navName='navName' :index='goldrushindex'></olympic-nav>
  </div>
</template>

<script>
export default {
  name: 'Gold-Rush',
  props: {
    goldData: {
      type: Array
    },
    showWhat: {
      type: Boolean
    }
  },
  components: {
    'olympic-nav': () => import(/* webpackChunkName: "hotpoint" */ '../../components/OlympicNav'),
  },
  created() {
    this.navName = this.showWhat ? '赛事逆时针': '赛场聚光灯'
  },
  computed: {
    goldrushdata() {
      return this.showWhat ? this.goldData.slice(1,4): this.goldData.slice(1,6)
    },
    goldrushindex() {
      return this.showWhat ? 1: 4
    }
  },
  data() {
    return {
      navName: '赛事逆时针',
      autoplay: this.$Is_PC ? 3000 : '',
      loop: this.$Is_PC ? true : false
    }
  },
}
</script>

<style scoped lang="less">
.gold-rush {
  position: relative;
  height: 352px;
  border-radius: 10px 10px 0 0;
  .gold-tilte {
    z-index: 1;
    position: absolute;
    top: 17px;
    left: -10px;
    box-sizing: border-box;
    padding: 9px 23px 9px 12px;
    height: 36px;
    background: #f7b92b;
    border-radius: 0 54px 54px 0;
    font-size: 20px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 2px 5px rgba(233, 119, 35, 0.8);
  }
  .gold-body {
    background: #f5fbff;
    border-radius: 10px;
    box-shadow: 0px 0px 18px 0px rgba(55, 170, 251, 0.27) inset;
    .seize-a-seat{
      width: 100%;
      height: 26px;
      border-radius: 0 0 10px 10px;
    }
    .my-swipe {
      height: 100%;
      border-radius: 10px;
      .van-swipe-item{
        height: 100%;
      }
    }
    .swipe-box {
      display: flex;
      flex-direction: column;
      .swipe-img {
        width: 100%;
        height: 199px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px 10px 0 0;
          background: #5397eb;
          object-fit: cover;
        }
      }
      .swipe-title {
        width: 297px;
        height: 16px;
        margin: 16px auto 10px 12px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #3c5bae;
        line-height: 16px;
      }
      .swipe-abstract {
        width: 326px;
        margin-left: 12px;
        margin-bottom: 25px;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #999999;
        line-height: 18px;
      }
    }
    /deep/.van-swipe__indicators {
      border-radius: 2px;
      background-color: #e9edf2;
      .van-swipe__indicator {
        width: 14px;
        height: 4px;
        margin-right: 0;
        border-radius: 2px;
      }
      .van-swipe__indicator--active {
        background-color: #f7321c !important;
      }
    }
  }
}
</style>
